.root {
  flex-grow: 1;
  overflow: auto;
  padding: 0 3;
  max-height: 100%;
}

.upper-container {
  margin-top: 50 !important;
  margin-bottom: 50;
}

.lower-container {
  bottom: 0; 
  background-color: "#fff"; 
  margin-top: 20; 
  margin-bottom: 0;
}

.k-for-contact-message {
  color: '#fff' !important;
}

.newseed-message {
  max-width: 400; 
  font-family: "Helvetica";
}

.logo {
  width: 200; 
  margin-bottom: 20; 
  margin-top: 20;
}

.link-button {
  background-color:"#3788D0";
  color: "#fff"; 
  margin-bottom: 20;
}

