
/* Media queries for K for Contact Logo */

@media screen and (max-width: 600px) {
  .center-logo {
    position: fixed;
    top: 40%;
    left: 23%;
    width: 50%;
  }
}

@media only screen and (min-width: 600px) {
  .center-logo {
    position: fixed;
    top: 40%;
    left: 24%;
  }
}

@media only screen and (min-width: 768px) {
  .center-logo {
    position: fixed;
    top: 40%;
    left: 40%;
  }
}

@media only screen and (min-width: 1028px) {
  .center-logo {
    position: fixed;
    top: 40%;
    left: 45%;
  }
}