body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.background-gradient-linear{
  position:fixed;
  width: 100vw;
  top:0;
  left:0;
  background-image: linear-gradient(0.45turn,#3E5DBC, #30A4DC);
  height: 100vh;
  z-index: -10;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.csv-input-container {
  margin: 20px 0;
}

.mb-10 { margin-bottom: 10px !important; }
.mt-0 { margin-top: 0 !important; }
.align-center { text-align: center; }

.warning-error-wrapper {
  display: table;
  width: 100%;
}

.warning-error-wrapper .errors-wrapper, .warning-error-wrapper .warnings-wrapper {
  width: calc(50% - 10px);
  display: table-cell;
  border-radius: 3px;
  overflow: auto !important;
}

.errors-wrapper { float: left; height: auto; }
.warnings-wrapper { float: right; overflow: auto; }

.warnings-wrapper .warnings-title span {
  margin-left: 20px;
  font-size: 12px;
  text-transform: capitalize;
}

.import-preview { margin-bottom: 40px; }
.import-preview table thead th, .warning-error-wrapper table .field-cell { text-transform: capitalize; }

.download-template { float: right; }

.import-label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.import-label.disabled { color: red; }
.action-btn {
  margin: 5px !important;
}
.export-link { text-decoration: none; }

.import-instructions span {
  display: block;
  padding: 0 0 5px 20px;
}

.list-indent {
  margin-top: 10px;
}